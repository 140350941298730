















































































































































































import { Component, Vue } from 'vue-property-decorator'
import AddCustomerForm from '@/components/forms/AddCustomerForm.vue'
import CategoriesCard from '@/components/categories/CategoriesCard.vue'
import AddCategoryCard from '@/components/categories/AddCategoryCard.vue'
import { GeocodeBaseResponse, GeocodeBaseResponseCopyright } from '@/apis/mapquest/geocodeBaseResponse'
import { GeocodeAddressResponse } from '@/apis/mapquest/geocodeAddressResponse'
import { GeocodeAddress } from '@/apis/mapquest/geocodeAddressRequest'
import SetupStore from '@/store/modules/SetupStore'
import { CustomerObject } from '@/data/customer'
import { customersStore } from '@/data/customer/lib/customersStore'
import { CategoryObject } from '@/data/category'

@Component({
  components: { CategoriesCard, AddCategoryCard, AddCustomerForm }
})
export default class CustomersVueComponent extends Vue {
  public isRequestingAddress = false
  public addressRequestCopyright: GeocodeBaseResponseCopyright | null = null
  public addressResponseLocation: GeocodeAddressResponse | null = null
  public staticMapUrl = ''

  public fields = [{
    key: 'avatar',
    sortable: false
  }, {
    key: 'name',
    sortable: true
  }, {
    key: 'street',
    sortable: true
  }, {
    key: 'zipCode',
    sortable: true
  }, {
    key: 'city',
    sortable: true
  }, {
    key: 'phone',
    sortable: true
  }, {
    key: 'categories',
    sortable: true,
    formatter: (value: CategoryObject | undefined): string => {
      return value?.name ?? '-'
    }
  }, {
    key: 'actions',
    sortable: false
  }]

  get customerItems (): Array<CustomerObject> {
    return customersStore.customers
  }

  get isCustomersRestricted (): boolean {
    return customersStore.isRestricted
  }

  /**
   * The mapquest map url for the address preview.
   */
  get addressRequestMapUrl (): string {
    const divWidth = this.$refs.mapColWidth as HTMLElement | null
    var imageWidth = divWidth?.clientWidth ?? 400
    imageWidth = imageWidth > 170 ? imageWidth : 170
    if (this.addressResponseLocation) {
      const url = new URL(this.addressResponseLocation.mapUrl)
      url.searchParams.set('size', `${imageWidth},400`)
      url.searchParams.set('type', 'map')
      url.searchParams.delete('scalebar')
      return url.toString()
    }
    return this.staticMapUrl
  }

  public triggeredRequestingAddress (isRequesting: boolean): void {
    this.isRequestingAddress = isRequesting
  }

  public triggeredAddressResult (result?: GeocodeBaseResponse): void {
    if (result) {
      this.addressRequestCopyright = result.info.copyright
      console.log(result)
      if (result.results) {
        this.addressResponseLocation = result.results[0].locations[0]
      }
    } else {
      this.resetAddressResult()
    }
  }

  public resetAddressResult (): void {
    this.addressRequestCopyright = null
    this.addressResponseLocation = null
  }

  public onAddCustomerTabClicked (): void {
    this.$nextTick(() => {
      const divWidth = this.$refs.mapColWidth as HTMLElement | null
      var imageWidth = divWidth?.clientWidth ?? 400
      imageWidth = imageWidth > 170 ? imageWidth : 170
      if (!this.staticMapUrl) {
        console.log(imageWidth)
        const geocodeAddress = new GeocodeAddress()
        this.staticMapUrl = geocodeAddress.getStaticGermanyMapUrl(SetupStore.mapquestKey ?? '', imageWidth)
      }
    })
  }

  public mounted (): void {
    customersStore.registerComponent(this.$options.name)
  }

  public beforeDestroy (): void {
    customersStore.unregisterComponent(this.$options.name)
  }
}
