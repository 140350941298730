import { Module } from 'vuex-class-modules'
import store from '@/store'
import { CustomersInstance, CustomerObject } from '../'
import { DataStore } from '@/store/lib/dataStore'

@Module
export class CustomersStore extends DataStore<CustomerObject> {
  get customers (): Array<CustomerObject> {
    return this.data
  }

  protected startListening (): void {
    if (!this.isRestricted) {
      const customersInstance = CustomersInstance.getInstance()
      if (!customersInstance.isListeningForCustomers) {
        customersInstance.startListeningForCustomers()
      }
    } else {
      this.stopListening()
    }
  }

  protected stopListening (): void {
    const customersInstance = CustomersInstance.getInstance()
    customersInstance.stopListeningForCustomers()
  }
}

export const customersStore = new CustomersStore({ store: store, name: 'customers' })
