import ApiUtils from '../crud-wrapper/apiUtils'
import { GeocodeBaseResponse } from './geocodeBaseResponse'
import { EMapquestIntlMode, EMapquestOutFormat, MapquestApiObject } from './mapquestApi'

/**
 * ## Example Request
 *
 * ```sh
 * GET http://www.mapquestapi.com/geocoding/v1/address?key=KEY&location=Washington,DC
 * ```
 *
 * ## Example Response
 *
 * ```json
 *  {
 *    "info": {
 *      "statuscode": 0,
 *      "copyright": {
 *        "text": "© 2018 MapQuest, Inc.",
 *        "imageUrl": "http://api.mqcdn.com/res/mqlogo.gif",
 *        "imageAltText": "© 2018 MapQuest, Inc."
 *      },
 *      "messages": []
 *    },
 *    "options": {
 *      "maxResults": -1,
 *      "thumbMaps": true,
 *      "ignoreLatLngInput": false
 *    },
 *    "results": [
 *      {
 *        "providedLocation": {
 *          "location": "Washington,DC"
 *        },
 *        "locations": [
 *          {
 *            "street": "",
 *            "adminArea6": "",
 *            "adminArea6Type": "Neighborhood",
 *            "adminArea5": "Washington",
 *            "adminArea5Type": "City",
 *            "adminArea4": "District of Columbia",
 *            "adminArea4Type": "County",
 *            "adminArea3": "DC",
 *            "adminArea3Type": "State",
 *            "adminArea1": "US",
 *            "adminArea1Type": "Country",
 *            "postalCode": "",
 *            "geocodeQualityCode": "A5XAX",
 *            "geocodeQuality": "CITY",
 *            "dragPoint": false,
 *            "sideOfStreet": "N",
 *            "linkId": "282772166",
 *            "unknownInput": "",
 *            "type": "s",
 *            "latLng": {
 *              "lat": 38.892062,
 *              "lng": -77.019912
 *            },
 *            "displayLatLng": {
 *              "lat": 38.892062,
 *              "lng": -77.019912
 *            },
 *            "mapUrl": "http://www.mapquestapi.com/staticmap/v4/getmap?key=KEY&type=map&size=225,160&pois=purple-1,38.892062,-77.019912,0,0,|&center=38.892062,-77.019912&zoom=12&rand=306744981"
 *          }
 *        ]
 *      }
 *    ]
 *  }
 * ```
 */
export interface GeocodeAddressRequest extends MapquestApiObject {
  /**
   * The location to geocode.
   *
   * **Example**: Single Line Location
   *
   * `location=Denver,CO`
   *
   * **Required**
   */
  location: string
  /**
   * When ambiguous results are returned, any results within the provided
   * bounding box will be moved to the top of the results list. Bounding box
   * format is: `upper left latitude`, `upper left longitude`, `lower right
   * latitude`, `lower right longitude`.
   *
   * **Example**: Bounding Box
   *
   * `boundingBox=40.099998,-77.305603,39.099998,-75.305603`
   *
   * *Optional*
   */
  boundingBox?: string
  /**
   * This option tells the service whether it should fail when given a
   * latitude/longitude pair in an address or batch geocode call, or if it
   * should ignore that and try and geocode what it can.
   *
   * * `true`: The geocoder will ignore the LatLng specified in the location, and
   * use the address info to perform geocode.
   * * `false`: The geocoder will return a geoaddress object containing the
   * LatLng passed in and write a warning message to Info block.
   *
   * **Example**: Ignore Lat Lng Input
   *
   * `ignoreLatLngInput=true`
   *
   * *Optional, defaults to __false__*
   */
  ignoreLatLng?: boolean
  /**
   * This parameter tells the service whether it should return a URL to a static
   * map thumbnail image for a location being geocoded.
   *
   * * `true`: The response will include a URL to a static map image of the
   * geocoded location.
   * * `false`: The response will not include a static map image URL.
   *
   * **Example**: Disable Static Map Response
   *
   * `thumbMaps=false`
   *
   * *Optional, defaults to __true__*
   */
  thumbMaps?: boolean
  /**
   * The max number of locations to return from the geocode..
   *
   * This option will only have an effect on the second location because it is
   * ambiguous.
   *
   * **Example**: Five Results Max
   *
   * `maxResults=5`
   *
   * *Optional*
   */
  maxResults?: number
  /**
   * Specifies the format of the response. Must be one of the following, if
   * supplied:
   *
   * * json
   * * xml
   * * csv (character delimited)
   *
   * **Example**: XML response
   *
   * `outFormat=xml`
   *
   * *Optional, defaults to __json__*
   */
  outFormat?: EMapquestOutFormat
  /**
   * Specifies the delimiter used in the csv response.
   *
   * * ,
   * * |
   * * :
   * * ;
   *
   * **Example**: Pipe Delimiter
   *
   * `delimiter=|`
   *
   * *Optional, defaults to __none__, only in effect if outFormat=csv*
   */
  delimiter?: string
  /**
   * Allows users of the International Geocoder to tell MapQuest how to handle a
   * 5-box geocode.
   *
   * * `5BOX`: Keeps the query as a 5-box and sends it to the TomTom International
   * Geocoder as a 5-box.
   * * `1BOX`: Converts the 5-box query into a 1-box query and sends it to the
   * TomTom International Geocoder.
   * * `AUTO`: Handles the query in a way deemed most optimal by MapQuest.
   * Currently, this converts a 5-box query to a 1-box query across the board.
   *
   * **Example**: 1BOX International Mode
   *
   * `intlMode=1BOX`
   *
   * *Optional, defaults to __auto__*
   */
  intlMode?: EMapquestIntlMode
  /**
   * A JavaScript function name. The JSON-formatted response will be wrapped in
   * a call to the supplied callback function name to provide JSONP
   * functionality. This functionality might be needed to do cross-site
   * scripting. See the Wikipedia.org entry for JSON for more details.
   *
   * **Example**: Callback
   *
   * `callback=geocodeResult`
   *
   * *Optional - __Not implemented__*
   */
  callback?: string
}

export class GeocodeAddress<Req extends GeocodeAddressRequest> {
  private baseUrl: string
  private addressRequestUrlPath: string
  private staticMapUrlPath: string

  constructor () {
    this.baseUrl = ApiUtils.mapquestBaseUrl()
    this.addressRequestUrlPath = '/geocoding/v1/address'
    this.staticMapUrlPath = '/staticmap/v5/map'
  }

  get addressRequestUrl (): string {
    return `${this.baseUrl}${this.addressRequestUrlPath}`
  }

  get staticMapRequestUrl (): string {
    return `${this.baseUrl}${this.staticMapUrlPath}`
  }

  public getStaticGermanyMapUrl (mapquestKey: string, width = 400, height = 400): string {
    const url = new URL(this.staticMapRequestUrl)
    url.searchParams.set('key', mapquestKey)
    url.searchParams.set('center', 'Germany')
    url.searchParams.set('zoom', '6')
    url.searchParams.set('type', 'map')
    url.searchParams.set('size', `${width},${height}`)
    return url.toString()
  }

  public async requestAddress (request: Req): Promise<GeocodeBaseResponse | undefined> {
    const response = await ApiUtils.getJsonResource(this.addressRequestUrl, this.searchParamsForRequest(request))
    if (response.ok) {
      return response.json() as Promise<GeocodeBaseResponse>
    }
    return undefined
  }

  /**
   * This method creates all URL parameters out of the request object. To be
   * included in the parameters, the regarding property needs to be set.
   * @param request The GeocodeAddressRequest object.
   * @returns The URL search params, containing all parameters that are defined.
   */
  private searchParamsForRequest (request: Req): URLSearchParams {
    const params = new URLSearchParams()
    params.set('key', request.key)
    params.set('location', request.location)
    if (request.boundingBox) {
      params.set('boundingBox', request.boundingBox)
    }
    if (request.ignoreLatLng !== undefined) {
      params.set('ignoreLatLng', request.ignoreLatLng ? 'true' : 'false')
    }
    if (request.thumbMaps !== undefined) {
      params.set('thumbMaps', request.thumbMaps ? 'true' : 'false')
    }
    if (request.maxResults) {
      params.set('maxResults', request.maxResults.toString())
    }
    if (request.outFormat) {
      params.set('outFormat', request.outFormat)
    }
    if (request.delimiter) {
      params.set('delimiter', request.delimiter)
    }
    if (request.intlMode) {
      params.set('intlMode', request.intlMode)
    }
    return params
  }
}
