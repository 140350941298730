






































































import { Component, Vue } from 'vue-property-decorator'
import { CategoriesHandler, CategoryObject } from '@/data/category'
import EditCategoryForm from '@/components/categories/EditCategoryForm.vue'
import CardSkeleton from '@/components/skeletons/CardSkeleton.vue'
import { ResponseError } from '@/data/error'
import { categoriesStore } from '@/data/category/lib/categoriesStore'

@Component({
  components: { EditCategoryForm, CardSkeleton }
})
export default class CategoriesCard extends Vue {
  public editCategoryKey: string | null = null
  public deleteCategory: CategoryObject | null = null
  public isDeletable = false
  public showDeleteCategoryConfirmation = false

  public currentPage = 1
  public perPage = 10

  get categories (): Array<CategoryObject> {
    return categoriesStore.categories
  }

  get isCategoriesRestricted (): boolean {
    return categoriesStore.isRestricted
  }

  get filteredCategories (): Array<CategoryObject> {
    return this.categories.slice(
      (this.currentPage - 1) * this.perPage,
      this.currentPage * this.perPage
    )
  }

  /**
   * Switch mode to be able to delete categories.
   *
   * This method will switch the bool value of the `isDeletable` property. If
   * this property is set to `true` the delete buttons will appear nearby each
   * category entry in the list.
   */
  public setCategoriesDeletable (): void {
    this.isDeletable = !this.isDeletable
  }

  /**
   * Edit a single category. With this method the edit category form will be
   * displayed.
   */
  public onEditCategory (key: string): void {
    this.editCategoryKey = key
  }

  /**
   * This method is called when the edit category form emits the cancel event.
   */
  public onCancelledEditCategoryForm (): void {
    this.editCategoryKey = null
  }

  /**
   * Show confirmation dialog to delete a single category.
   */
  public showDeleteCategoryDialog (category: CategoryObject): void {
    this.showDeleteCategoryConfirmation = true
    this.deleteCategory = category
  }

  /**
   * Delete the currently selected category.
   */
  public onDeleteCategoryConfirm (): void {
    if (this.deleteCategory) {
      CategoriesHandler.removeCategory(this.deleteCategory).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.deleteCategory.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.showDeleteCategoryConfirmation = false
        this.deleteCategory = null
      })
    }
  }

  /**
   * Cancel delete of a category. This will close the confirmation dialog.
   */
  public onDeleteCategoryCancel (): void {
    this.showDeleteCategoryConfirmation = false
    this.deleteCategory = null
  }

  public mounted (): void {
    categoriesStore.registerComponent(this.$options.name)
  }

  public beforeDestroy (): void {
    categoriesStore.unregisterComponent(this.$options.name)
  }
}
