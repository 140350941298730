import constants from '@/common/constants'
import { FirebaseAppInstance } from '@/data/app'
import {
  DatabaseReference, DataSnapshot,
  getDatabase, ref, off,
  onChildAdded, onChildChanged, onChildRemoved
} from 'firebase/database'
import { CustomerObject, CustomerCategory } from '../'
import { customersStore } from './customersStore'

export class CustomersInstance {
  private static instance: CustomersInstance
  private customersRef: DatabaseReference
  public isListeningForCustomers = false

  constructor () {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    this.customersRef = ref(db, constants.DB_CUSTOMERS)
  }

  public static getInstance (): CustomersInstance {
    if (!CustomersInstance.instance) {
      CustomersInstance.instance = new CustomersInstance()
    }
    return CustomersInstance.instance
  }

  /**
   * Start listening for changes of customers in the database.
   *
   * This includes listening on the `childAdded`, `childChanged` and
   * `childRemoved` -events. The customers are then accessable by using the
   * `customers`-getter in the `customersStore`.
   *
   * To stop listening for these events use the `stopListeningForCustomers`
   * method.
   */
  public startListeningForCustomers (): void {
    this.stopListeningForCustomers()
    this.isListeningForCustomers = true

    // Child added
    onChildAdded(this.customersRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        customersStore.add(this.convertSnapshotToCustomer(snapshot, snapshot.key))
      }
    })

    // Child changed
    onChildChanged(this.customersRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const newCustomer = this.convertSnapshotToCustomer(snapshot, snapshot.key)
        customersStore.update(newCustomer)
      }
    })

    // Child removed
    onChildRemoved(this.customersRef, (snapshot) => {
      customersStore.remove(snapshot.key)
    })
  }

  /**
   * Stop listening for changes of customers in the database.
   */
  public stopListeningForCustomers (): void {
    off(this.customersRef)
    customersStore.clearData()
    this.isListeningForCustomers = false
  }

  /**
   * Convert a firebase `DataSnapshot` to a CustomerObject.
   *
   * @param snapshot The firebase snapshot.
   * @param key The key of the snapshot.
   * @returns The converted CustomerObject.
   */
  private convertSnapshotToCustomer (snapshot: DataSnapshot, key: string): CustomerObject {
    const snapshortCategories = snapshot.val().categories as Record<string, CustomerCategory>
    const customerCategories = this.convertObjectCategoriesToArray(snapshortCategories)

    const customer = snapshot.val() as CustomerObject
    customer.key = key
    customer.categories = customerCategories
    return customer
  }

  private convertObjectCategoriesToArray (objectCategories: Record<string, CustomerCategory>): Array<CustomerCategory> {
    const customerCategories: Array<CustomerCategory> = []
    if (objectCategories) {
      for (const index in objectCategories) {
        const customerCategory = objectCategories[index]
        customerCategory.key = index
        customerCategories.push(customerCategory)
      }
    }
    return customerCategories
  }
}
