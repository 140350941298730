export default class ApiUtils {
  /**
   * Get the base url to the mapquest api backend.
   * @returns The base url to the mapquest api.
   */
  public static mapquestBaseUrl (): string {
    return 'http://mapquestapi.com'
  }

  /**
   * Start a request using the `post` method.
   * @param url The request's url.
   * @param resource The request's post body.
   * @returns The `Response`-object.
   */
  // eslint-disable-next-line
  public static async postJsonResource (url: string, resource: any): Promise<Response> {
    const request = new Request(url, {
      method: 'POST',
      cache: 'default',
      mode: 'no-cors',
      headers: ApiUtils.headers(),
      body: JSON.stringify(resource)
    })
    return fetch(request)
  }

  /**
   * Start a request using the `get` method.
   * @param url The request's url.
   * @param params The request's parameters.
   * @returns The `Response`-object.
   */
  public static async getJsonResource (url: string, params: URLSearchParams): Promise<Response> {
    const requestURL = `${url}?${params.toString()}`
    const request = new Request(requestURL, {
      method: 'GET',
      cache: 'default',
      headers: ApiUtils.headers()
    })
    return fetch(request)
  }

  /**
   * Add the `Content-Type` header set to `application/json`.
   * @returns The `HeadersInit`-object.
   */
  private static headers (): HeadersInit {
    const requestHeader = new Headers()
    requestHeader.set('Content-Type', 'application/json')
    return requestHeader
  }
}
