












import { Component, Vue } from 'vue-property-decorator'
import AddCategoryForm from '@/components/categories/AddCategoryForm.vue'
import CardSkeleton from '@/components/skeletons/CardSkeleton.vue'
import { authenticationStore } from '@/store/modules/authenticationStore'

@Component({
  components: { AddCategoryForm, CardSkeleton }
})
export default class AddCategoryCard extends Vue {
  get isAdmin (): boolean | null {
    return authenticationStore.isAdministrator
  }
}
