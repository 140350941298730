




























import { Component, Prop, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { CategoriesHandler, CategoryObject } from '@/data/category'
import { ResponseError } from '@/data/error'

@Component
export default class EditCategoryForm extends Vue {
  @Prop() category!: CategoryObject

  public formName = this.category.name ?? ''
  public formColor = this.category.color ?? ''

  public formCheck: FormCheck = new FormCheck()
  public isLoading = false

  get stateName (): false | null {
    return this.formCheck.findFormError('categoryName') ? false : null
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('categoryName', this.formName)
    this.formCheck.checkLengthGreater('categoryName', this.formName, 2)
    this.formCheck.checkLengthLessThan('categoryName', this.formName, 24)
    return this.formCheck.hasErrors()
  }

  /**
   * Trim category name to remove whitespaces.
   */
  public categoryNameFormatter (value: string): string {
    return value.trim()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.formName = this.category.name
    this.formColor = this.category.color
    this.formCheck.clearFormErrors()
  }

  /**
   * Cancel form. This method will emit the `cancelled-edit-category-form`-event.
   */
  public cancelForm (): void {
    this.resetForm()
    this.$emit('cancelled-edit-category-form')
  }

  /**
   * Submit form. This method will check for any changes. If there are no
   * changes, the `cancelled-edit-category-form`-event will be emitted.
   */
  public submitForm (): void {
    if (this.formName === this.category.name && this.formColor === this.category.color) {
      // There are no changes, database writes would be too expensive.
      this.$emit('cancelled-edit-category-form')
      return
    }
    this.isLoading = true
    if (!this.checkForm()) {
      CategoriesHandler.updateCategoryProperties(this.category, {
        name: this.formName,
        color: this.formColor
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.error'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.$emit('cancelled-edit-category-form')
      })
    }
    this.isLoading = false
  }
}
