





















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import FormCheck from '@/common/formCheck'
import { CustomersHandler, AddCustomerFormObject } from '@/data/customer'
import { CategoryObject } from '@/data/category'
import { GeocodeAddress } from '@/apis/mapquest/geocodeAddressRequest'
import SetupStore from '@/store/modules/SetupStore'
import { ResponseError } from '@/data/error'
import { categoriesStore } from '@/data/category/lib/categoriesStore'

@Component
export default class AddCustomerForm extends Vue {
  public selectedCategoryKeys: Array<string> = []
  public searchString = ''

  public debounceAddressRequest?: number
  public formCheck: FormCheck = new FormCheck()
  public isLoading = false
  public responseError: string | null = null
  public showResponseAlert = false
  public customer: AddCustomerFormObject = {
    name: '',
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    state: '',
    country: 'DE',
    addressAdditional: '',
    phone: '',
    stay: null,
    receiver: null,
    isSign: null,
    safeDelivery: null,
    info: null,
    categories: []
  }

  get categories (): Array<CategoryObject> {
    return categoriesStore.categories
  }

  get stateName (): false | null {
    return this.formCheck.findFormError('customerName') ? false : null
  }

  get stateCountry (): false | null {
    return this.formCheck.findFormError('country') ? false : null
  }

  get stateCity (): false | null {
    return this.formCheck.findFormError('city') ? false : null
  }

  get stateZipCode (): false | null {
    return this.formCheck.findFormError('zipCode') ? false : null
  }

  get stateStreet (): false | null {
    return this.formCheck.findFormError('street') ? false : null
  }

  get stateHouseNumber (): false | null {
    return this.formCheck.findFormError('houseNumber') ? false : null
  }

  get countryOptions (): Array<string> {
    return ['DE']
  }

  /**
   * Mapper to convert selected categories into the corresponding
   * `CategoryObject`.
   *
   * The selected categories are provided by an array of strings, consisting of
   * the category's `key` value.
   */
  get selectedCategories (): Array<CategoryObject> {
    return this.categories.filter(category => this.selectedCategoryKeys.includes(category.key))
  }

  /**
   * Filter categories that available for selecting.
   */
  get selectableCategories (): Array<CategoryObject> {
    const searchCriteria = this.searchCriteria()
    // Filter out already selected options
    const selectables = this.categories.filter(category => this.selectedCategoryKeys.indexOf(category.key) === -1)
    if (searchCriteria) {
      // Show only options that match criteria
      return selectables.filter(category => category.name.toLowerCase().indexOf(searchCriteria) > -1)
    }
    // Show all options available
    return selectables
  }

  get isAddressDataComplete (): boolean {
    return this.customer.street !== '' &&
      this.customer.houseNumber !== '' &&
      this.customer.city !== '' &&
      this.customer.zipCode !== '' &&
      this.customer.country !== ''
  }

  get addressLocationString (): string {
    return ''.concat(
      `${this.customer.street} `,
      `${this.customer.houseNumber}, `,
      `${this.customer.zipCode} `,
      `${this.customer.city}, `,
      `${this.customer.country}`
    )
  }

  public prepareRequestingAddress (): void {
    clearTimeout(this.debounceAddressRequest)
    this.debounceAddressRequest = window.setTimeout(() => {
      if (this.isAddressDataComplete) {
        this.$emit('is-requesting-address', true)
        this.requestAddress()
      } else {
        this.$emit('is-requesting-address', false)
        this.$emit('reset-address-result')
      }
    }, 800)
  }

  public async requestAddress (): Promise<void> {
    const req = new GeocodeAddress()
    const res = await req.requestAddress({
      location: this.addressLocationString, key: SetupStore.mapquestKey ?? '', maxResults: 1
    })
    this.$emit('is-requesting-address', false)
    this.$emit('request-address-result', res)
    if (res && res.results) {
      this.customer.state = res.results[0].locations[0].adminArea3
    }
  }

  /**
   * Submit the add customer form.
   */
  public submitAddCustomer (): void {
    this.isLoading = true
    if (!this.checkForm()) {
      this.customer.categories = this.selectedCategories
      CustomersHandler.createCustomer(this.customer).then(() => {
        this.$root.$bvToast.toast(
          this.$tc('databaseResults.addCustomer.success', 0, { customerName: this.customer.name }), {
            title: this.$tc('databaseResults.addCustomer.title'),
            autoHideDelay: 15000,
            appendToast: true,
            solid: true,
            variant: 'success'
          }
        )
        this.resetForm()
      }).catch((error) => {
        if (error.code) {
          error.name = error.code
        }
        const responseError = new ResponseError(error.name, error.message)
        const errorLocaleKey = responseError.getDatabaseErrorLocaleKey()
        this.$root.$bvToast.toast(this.$tc(errorLocaleKey), {
          title: this.$tc('databaseResults.addCustomer.title'),
          autoHideDelay: 15000,
          appendToast: true,
          solid: true,
          variant: 'danger'
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
    this.isLoading = false
  }

  /**
   * Check the form input against rules before submitting.
   */
  public checkForm (): boolean {
    this.formCheck.clearFormErrors()
    this.formCheck.checkTextRequired('customerName', this.customer.name)
    this.formCheck.checkLengthGreater('customerName', this.customer.name, 2)
    this.formCheck.checkLengthLessThan('customerName', this.customer.name, 128)
    this.formCheck.checkTextRequired('street', this.customer.street)
    this.formCheck.checkLengthGreater('street', this.customer.street, 2)
    this.formCheck.checkLengthLessThan('street', this.customer.street, 128)
    this.formCheck.checkTextRequired('houseNumber', this.customer.houseNumber)
    this.formCheck.checkLengthLessThan('houseNumber', this.customer.houseNumber, 10)
    this.formCheck.checkTextRequired('city', this.customer.city)
    this.formCheck.checkLengthGreater('city', this.customer.city, 2)
    this.formCheck.checkLengthLessThan('city', this.customer.city, 128)
    this.formCheck.checkTextRequired('country', this.customer.country)
    this.formCheck.checkTextRequired('zipCode', this.customer.zipCode)
    this.formCheck.checkZipCodeFormat('zipCode', this.customer.zipCode)
    return this.formCheck.hasErrors()
  }

  /**
   * Reset all form errors and form inputs.
   */
  public resetForm (): void {
    this.customer = {
      name: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      state: '',
      country: 'DE',
      addressAdditional: '',
      phone: '',
      stay: null,
      receiver: null,
      isSign: null,
      safeDelivery: null,
      info: null,
      categories: []
    }
    this.selectedCategoryKeys = []
    this.formCheck.clearFormErrors()
    this.$emit('is-requesting-address', false)
    this.$emit('reset-address-result')
  }

  public resetStay (): void {
    this.customer.stay = null
  }

  public resetReceiver (): void {
    this.customer.receiver = null
  }

  public resetSign (): void {
    this.customer.isSign = null
  }

  /**
   * Clear the states' error when the alert is dismissed.
   */
  public dismissedResponseAlert (): void {
    this.responseError = null
  }

  /**
   * Criteria to search categories. This method converts the search string to be
   * trimmed and lower cased.
   */
  public searchCriteria (): string {
    return this.searchString.trim().toLowerCase()
  }

  /**
   * Method to add a selected `CategoryObject` to the list of selected
   * categories.
   *
   * Selected categories are represented by an array strings consisting of the
   * corresponding category's `key` value.
   */
  // eslint-disable-next-line
  public onSelectCategory (category: CategoryObject, addTag: any): void {
    addTag(category.key)
    this.searchString = ''
  }

  public mounted (): void {
    categoriesStore.registerComponent(this.$options.name)
  }

  public beforeDestroy (): void {
    categoriesStore.unregisterComponent(this.$options.name)
  }
}
