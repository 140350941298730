import constants from '@/common/constants'
import { FirebaseAppInstance } from '@/data/app'
import { AddCategoryFormObject } from '@/data/category'
import { child, DatabaseReference, DataSnapshot, getDatabase, increment, off, onChildAdded, onChildChanged, onChildRemoved, push, ref, remove, serverTimestamp, update } from 'firebase/database'
import { AddCustomerFormObject, CustomerObject } from '..'

/**
 * Object interface to create a new customer in the firebase database.
 */
 interface DbAddCustomer extends Omit<AddCustomerFormObject, 'categories'> {
  categories: Record<string, AddCategoryFormObject>
  // eslint-disable-next-line
  created: any
}

export class CustomersHandler {
  private customersRef: DatabaseReference
  public customers: Array<CustomerObject>

  constructor () {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    this.customersRef = ref(db, constants.DB_CUSTOMERS)
    this.customers = []
  }

  /**
   * Start listening for changes of customers in the database.
   *
   * This includes listening on the `childAdded`, `childChanged` and
   * `childRemoved` -events. The customers are then accessable by using the
   * `customers`-property of this class.
   *
   * To stop listening for these events use the `stopCustomersListener` method.
   */
  public startCustomersListener (): void {
    // Child added
    onChildAdded(this.customersRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        this.customers.push(
          this.convertSnapshotToCustomer(snapshot, snapshot.key)
        )
      }
    })

    // Child changed
    onChildChanged(this.customersRef, (snapshot) => {
      if (snapshot.exists() && snapshot.key) {
        const index = this.customers.map(customer => customer.key).indexOf(snapshot.key)
        if (index > -1) {
          const newCustomer = this.convertSnapshotToCustomer(snapshot, snapshot.key)
          this.customers.splice(index, 1, newCustomer)
        }
      }
    })

    // Child removed
    onChildRemoved(this.customersRef, (snapshot) => {
      if (snapshot.key) {
        const index = this.customers.map(customer => customer.key).indexOf(snapshot.key)
        if (index > -1) {
          this.customers.splice(index, 1)
        }
      }
    })
  }

  /**
   * Stop listening for changes of customers in the database.
   *
   * In a Vue Component a good place to stop listening is the `destroyed`
   * lifecycle event.
   */
  public stopCustomersListener (): void {
    off(this.customersRef)
  }

  /**
   * Update custom properties of a customer.
   *
   * This will also add or update the `modified` property.
   * @param key The database key.
   * @param data The data dictionary-object containing all changes.
   * @returns Promise
   */
  // eslint-disable-next-line
  public async updateCustomerProperties (key: string, data: Record<string, any>): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const customerRef = ref(db, constants.DB_CUSTOMERS)

    await update(child(customerRef, key), {
      ...data,
      modified: serverTimestamp()
    })
    return Promise.resolve()
  }

  /**
   * Remove a customer.
   * @param customer The customer that should be removed.
   */
  public async removeCustomer (customer: CustomerObject): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const customerRef = ref(db, constants.DB_CUSTOMERS)

    await remove(child(customerRef, customer.key))
    Promise.resolve()
  }

  /**
   * Create a new customer by using the `AddCustomerForm`.
   * @param customer The `Customer`-Form object.
   * @returns Promise
   */
  public static async createCustomer (customer: AddCustomerFormObject): Promise<void> {
    const app = FirebaseAppInstance.getInstance().getApp(constants.APP_RELATED)
    const db = getDatabase(app)
    const customerRef = ref(db)
    const newPostKey = push(child(customerRef, constants.DB_CUSTOMERS)).key

    const categories = customer.categories.reduce((result:Record<string, AddCategoryFormObject>, category) => {
      const customerCategory: AddCategoryFormObject = {
        color: category.color,
        name: category.name
      }
      result[category.key] = customerCategory
      return result
    }, {})

    const newCustomer: DbAddCustomer = {
      ...customer,
      categories: categories,
      created: serverTimestamp()
    }

    // eslint-disable-next-line
    const updates: Record<string, any> = {}
    updates[constants.DB_CUSTOMERS + '/' + newPostKey] = newCustomer
    updates[constants.DB_STATISTICS + '/' + constants.DB_CUSTOMERS + '/count'] = increment(1)
    customer.categories.forEach(category => {
      updates[constants.DB_CATEGORIES + '/' + category.key + '/amountCustomers'] = increment(1)
      updates[constants.DB_CATEGORIES + '/' + category.key + '/' + constants.DB_CUSTOMERS + '/' + newPostKey] = true
    })

    await update(customerRef, updates)
    return Promise.resolve()
  }

  /**
   * Convert a firebase `DataSnapshot` to a CustomerObject.
   *
   * @param snapshot The firebase snapshot.
   * @param key The key of the snapshot.
   * @returns The converted CustomerObject.
   */
  private convertSnapshotToCustomer (snapshot: DataSnapshot, key: string): CustomerObject {
    const customer = snapshot.val() as CustomerObject
    customer.key = key
    return customer
  }
}
